import React from 'react'
import styled from 'styled-components'
import { colors, vars } from '../theme'

const Card = styled.div`
  background: #fff;
  min-width: 10rem;
  min-height: 5rem;
  padding: 1.5rem;
  border-radius: 2px;
  box-shadow: ${props =>
    props.large ? `0 0 0 3px ${colors.accent}` : `0 0 0 1px ${colors.accent}`};

  @media screen and (min-width: ${vars.screenSmall}) {
    padding: 1rem 2rem;
  }
`
const CardHeader = styled.div`
  margin-bottom: 0.5rem;
  display: flex;
  justify-content: space-between;
`
const Label = styled.div`
  font-size: 0.875rem;
  font-weight: 600;
  margin-top: -0.25rem;
`
const Dot = styled.div`
  height: 0.75rem;
  width: 0.75rem;
  background: currentColor;
  border-radius: 0.75rem;
`
const InfoCard = styled(
  ({ className, title, label, icon, iconSrc, children }) => (
    <Card className={className}>
      <div className={`timeline-card--${title}`}>
        <CardHeader className="timeline-card__header">
          {icon ? <img src={icon} alt={iconSrc} /> : <Dot />}
          <Label>{label}</Label>
        </CardHeader>
        <h4>{title}</h4>
        {children}
      </div>
    </Card>
  )
)`
  margin: 0.625rem 0;
  padding: 1.5rem;
  box-shadow: 0 0 0 1px ${colors.borderLight};

  h4 {
    margin: 0 0 0.5rem;
  }
`

export { Card, InfoCard }
