import React from 'react'
import styled from 'styled-components'
import { colors, vars } from '../../components/theme'
import { Card, InfoCard } from '../../components/common/Card'
import Checklist from '../../components/common/Checklist'

import { StaticQuery, graphql } from 'gatsby'

const calcPercentage = (amount, total) =>
  `${Math.round((amount / total) * 100)}%`
const sortByOrder = data => data.sort((a, b) => a.node.order - b.node.order)
const size = data =>
  sortByOrder(data).map(({ node: { months, totalMonths } }) =>
    calcPercentage(months, totalMonths)
  )
const List = ({ data }) =>
  data.list
    .split('- ')
    .slice(1)
    .map(item => <li key={item}>{item}</li>)

const Timeline = styled(({ data, className }) => (
  <div className={className}>
    <div className="timeline-bar timeline--learn" />
    <div className="timeline-bar timeline--practice" />
    <div className="timeline-bar timeline--participate" />
    <div className="markings" />
  </div>
))`
  display: grid;
  grid-template-columns: ${props => size(props.data)};
  position: relative;
  margin: 2rem -0.25rem 0;

  .timeline-bar {
    height: 1rem;
    border-radius: 1rem;
    margin: 0 0.25rem;
  }

  .timeline--learn {
    background: ${colors.primary};
  }
  .timeline--practice {
    background: ${colors.secondary};
  }
  .timeline--participate {
    background: ${colors.accent};
  }

  .markings {
    position: absolute;
    left: 0.25rem;
    top: 0;
    width: calc(100% - 0.5rem);
    height: 100%;
    background: repeating-linear-gradient(
      90deg,
      rgba(255, 255, 255, 0.8),
      rgba(255, 255, 255, 0.8) 1px,
      rgba(0, 0, 0, 0) 0,
      rgba(0, 0, 0, 0) 8.3333%
    );
    transform: translateX(-1px);
  }
`
const TimelineCards = styled(({ data, className }) => (
  <div className={className}>
    {sortByOrder(data).map(({ node: { id, title, months, description } }) => (
      <TimelineCard title={title} label={months + ' months'} key={id}>
        <div
          dangerouslySetInnerHTML={{
            __html: description.childContentfulRichText.html,
          }}
        />
      </TimelineCard>
    ))}
  </div>
))`
  display: grid;
  grid-gap: 1rem;
  margin: 1rem 0;

  @media (min-width: ${vars.screenMedium}) {
    grid-template-columns: repeat(3, 1fr);
  }
`
const TimelineCard = styled(InfoCard)`
  margin: 0;

  p {
    line-height: 1.4;
    font-size: 0.9rem;
  }

  .timeline-card--Learn .timeline-card__header {
    color: ${colors.primary};
  }
  .timeline-card--Practice .timeline-card__header {
    color: ${colors.secondary};
  }
  .timeline-card--Participate .timeline-card__header {
    color: ${colors.accent};
  }
`
const LearningItem = styled(Card)`
  padding: 1rem 1.5rem;

  h4 {
    margin-top: 0;
  }
`
const LearningContainer = styled.div`
  margin-top: 1rem;
  display: grid;
  grid-template: repeat(2, auto) / 1fr;
  grid-gap: 1rem;

  @media (min-width: ${vars.screenMedium}) {
    grid-template: repeat(2, auto) / repeat(2, 1fr);
  }
`
const Learnings = ({ data }) => (
  <>
    <h2>Angel Academy Program Details</h2>
    <LearningContainer>
      {sortByOrder(data).map(({ node: { id, title, list } }) => (
        <LearningItem key={id}>
          <h4>{title}</h4>
          <Checklist>
            <List data={list} />
          </Checklist>
        </LearningItem>
      ))}
    </LearningContainer>
  </>
)

const HowItWorksContent = ({ data }) => (
  <StaticQuery
    query={graphql`
      query {
        contentfulAngelAcademyPage(
          id: { eq: "c3c34c7f-b1de-5014-aef6-16ab2d89764d" }
        ) {
          id
          body {
            childContentfulRichText {
              html
            }
          }
        }
        allContentfulTimeLineCard {
          edges {
            node {
              id
              order
              title
              months
              description {
                childContentfulRichText {
                  html
                }
              }
              totalMonths
            }
          }
        }
        allContentfulAngelAcademyProgramDetails {
          edges {
            node {
              id
              order
              title
              list {
                id
                list
              }
            }
          }
        }
      }
    `}
    render={data => {
      const {
        contentfulAngelAcademyPage: { body },
        allContentfulTimeLineCard: { edges: timelineData },
        allContentfulAngelAcademyProgramDetails: { edges: programData },
      } = data

      return (
        <>
          {body && (
            <div
              dangerouslySetInnerHTML={{
                __html: body.childContentfulRichText.html,
              }}
            />
          )}
          <Timeline data={timelineData} />
          <TimelineCards data={timelineData} />
          <Learnings data={programData} />
        </>
      )
    }}
  />
)

export default HowItWorksContent
