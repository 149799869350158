import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import PageWrapper from '../../components/PageWrapper'
import Testimonials from '../Testimonials'

const WhatIsItContent = () => (
  <StaticQuery
    query={graphql`
      query {
        contentfulAngelAcademyPage(
          id: { eq: "9f202887-9c17-5177-bc7f-41908ff4a80d" }
        ) {
          id
          title
          pageIntro {
            heading
            subheading
            image {
              description
              file {
                url
              }
            }
          }
          body {
            childContentfulRichText {
              html
            }
          }
        }
        allContentfulAngelAcademyQuotes {
          edges {
            node {
              id
              order
              image {
                id
                file {
                  url
                }
              }
              quote {
                quote
              }
              author
              title
            }
          }
        }
      }
    `}
    render={data => {
      const {
        contentfulAngelAcademyPage: { body },
        allContentfulAngelAcademyQuotes: { edges: testimonialData },
      } = data

      return (
        <>
          <div
            dangerouslySetInnerHTML={{
              __html: body.childContentfulRichText.html,
            }}
          />

          <PageWrapper small>
            {testimonialData.length > 1 && (
              <h3>What Angel Academy Members Are Saying</h3>
            )}
            <Testimonials data={testimonialData} />
          </PageWrapper>
        </>
      )
    }}
  />
)
export default WhatIsItContent
