import styled from 'styled-components'
import { colors } from '../theme'
import iconCheckmark from '../../images/icons/icon-checkmark.svg'

const Checklist = styled.ul`
  font-size: ${props => (props.large ? '1.25rem' : '1rem')};
  line-height: 1.1;
  margin: 0.75rem 1.75rem;
  color: ${colors.grey};
  list-style-image: url(${iconCheckmark});

  li:not(:last-of-type) {
    margin-bottom: 0.375em;
  }
`

export default Checklist
