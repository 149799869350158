import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import Layout from '../../layouts'
import CohortApplicationForm from '../../components/CohortApplicationForm'
import PageWrapper from '../../components/PageWrapper'
import PageIntro from '../../components/PageIntro'
import HowItWorksContent from '../../components/angel-academy/HowItWorksContent'
import WhatIsItContent from '../../components/angel-academy/WhatIsItContent'
import WhoCanJoinContent from '../../components/angel-academy/WhoCanJoinContent'
import MetaHeader from '../../components/MetaHeader'
import { vars } from '../../components/theme'

const FormContainer = styled.div`
  padding-top: ${vars.navHeight};
`
const AngelAcademy = ({ data }) => {
  const {
    contentfulAngelAcademyPage: {
      pageIntro: {
        heading,
        subheading,
        image,
        metaTitle,
        metaDescription,
        metaKeywords,
        metaSiteUrl,
        metaTwitter,
      },
    },
  } = data

  return (
    <Layout>
      <MetaHeader
        title={metaTitle || heading}
        image={image?.file?.url}
        siteUrl={metaSiteUrl}
        description={metaDescription?.metaDescription}
        keywords={metaKeywords?.metaKeywords}
        twitter={metaTwitter}
      />

      <PageWrapper>
        <PageIntro
          title={heading}
          subtitle={subheading}
          imgSrc={image?.file?.url}
          alt={image?.title}
        />

        <WhatIsItContent />

        <h2>How Does It Work?</h2>
        <HowItWorksContent />

        <h2>Who can join?</h2>
        <WhoCanJoinContent />
      </PageWrapper>

      <FormContainer id="apply-now">
        <PageWrapper>
          <h2>Are You Ready?</h2>
          <section>
            <CohortApplicationForm />
          </section>
        </PageWrapper>
      </FormContainer>
    </Layout>
  )
}

export default AngelAcademy

export const query = graphql`
  query {
    contentfulAngelAcademyPage(
      id: { eq: "9f202887-9c17-5177-bc7f-41908ff4a80d" }
    ) {
      pageIntro {
        heading
        subheading
        image {
          file {
            url
          }
        }
        metaTitle
        metaDescription {
          metaDescription
        }
        metaKeywords {
          metaKeywords
        }
        metaSiteUrl
        metaTwitter
      }
    }
  }
`
