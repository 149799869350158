import React from 'react'
import { StaticQuery, graphql } from 'gatsby'

const HowItWorksContent = () => (
  <StaticQuery
    query={graphql`
      query {
        contentfulAngelAcademyPage(
          id: { eq: "14512eb0-88c2-5303-ba00-c49b0699bae4" }
        ) {
          id
          title
          pageIntro {
            heading
            subheading
            image {
              description
              file {
                url
              }
            }
          }
          body {
            childContentfulRichText {
              html
            }
          }
        }
      }
    `}
    render={data => {
      const {
        contentfulAngelAcademyPage: { body },
      } = data

      return (
        <div
          dangerouslySetInnerHTML={{
            __html: body.childContentfulRichText.html,
          }}
        />
      )
    }}
  />
)

export default HowItWorksContent
